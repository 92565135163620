import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip,LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend } from 'recharts';
import generatePDF2 from './generatePDF2';
import { Download } from 'lucide-react';

const Colo1 = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeView, setActiveView] = useState('questionnaire');
  const [formData, setFormData] = useState({
    coloProvider: 'Equinix',
    coloRate: 150,
    coloPowerCapacity: 1000,
    coloCoolingApproach: 'air',
    coloAirDistributionType: 'hotColdContainment',
    coloUpsStyle: 'onlineDoubleConversion',
    coloPowerDistributionType: 'basicPanelboards',
    coloRackCount: 10,
    coloCPUrackCount: 20,
    coloGPUrackCount: 30,
    coloGpuServersPerRack: 8,
    coloPowerDensity: 1,
    coloServerType: 'cpu',
    coloGpuBrand: 'NVIDIA',
    coloGpuModel: 'H200',
    coloNetworkType: 'Basic_Ethernet',
    coloOperatingSystem: 'linux',
    coloHpcSoftware: 'slurm',
    coloLaborRate: 28,
    coloItStaffCount: '1-5',
    coloFacilitiesStaff: false,
    coloFacilitiesStaffCount: 0,
    coloOutsourceHardware: true,
    coloMaintenanceCost: 10000,
    coloExpansion: true,
    coloExpansionTimeline: '1_2years',
    coloEnvironmentalGoals: true,
    coloPueTarget: 1.3,
    customization: false,
    customizationCost: '',
    bringOwnHardware: true,
  });

  const [coloCosts, setColoCosts] = useState({
    coloTotalCapEx: 0,
    coloMonthlyOpEx: 0,
    coloHardwareCost: 0,
    revenueData: [],
    coloPowerCost: 0,
    coloLaborCost: 0,
    coloMaintenanceCost: 0,
    coloSoftwareCost: 0,
    coloOtherCost: 0,
    coloPowerUsage: 0,
    coloServersCost: 0,
    coloNetworkingCost: 0,
  });

  // Enhanced cost calculation constants
  const COST_CONSTANTS = {
    SHELL_COST_PER_SQFT: 250,
    lease_rate_sqft_month: 2.00,
    COOLING_COST_BASE: {
      air: 400,    // $/kW
      liquid: 600, // $/kW
      hybrid: 500  // $/kW
    },
    POWER_DIST_BASE: 400, // $/kW
    UPS_BASE: 200,       // $/kW
    GEN_COST_PER_KW: 300,
    RACK_COST: {
      cpu: 100000,  // $ per rack
      gpu: 300000,
      both: 200000 // $ per rack
    },
    NETWORK_BASE_PER_RACK: 10000,
    infiniBand_multiplier: 2.5,
    Networking_type_multiplier: {
      Basic_Ethernet: 1.0,
      High_speed_interconnect: 2.5,
      Both: 1.5
    },
    POWER_RATE: 0.12,    // $/kWh
    PUE: {
      air: 1.4,
      liquid: 1.2,
      hybrid: 1.3
    },
    GPU_MODEL_COST: {
      A100_PCIe: 165000,
      A100_SXM: 220000,
      H100_PCIe: 300000,
      H100_SXM: 350000,
      H200: 385000,
    },
    GPU_MODELS_RATES_YEARLY: {
      A100_PCIe: { rates: [2.0, 1.8, 1.5, 1.2, 0.8] },  // Rates for 2025-2029
      A100_SXM: { rates: [2.5, 2.0, 1.7, 1.3, 0.9] },   // Rates for 2025-2029
      H100_PCIe: { rates: [3.5, 3.0, 2.0, 1.5, 0.7] },  // Rates for 2025-2029
      H100_SXM: { rates: [3.0, 2.5, 1.5, 1.0, 0.3] },   // Rates for 2025-2029
      H200: { rates: [4.0, 3.5, 2.0, 1.5, 0.6] }        // Rates for 2025-2029
    }
    
  };

  const calculateColoCosts = () => {
    // ---------------------------
    // 1) Colocation-Specific Costs
    // ---------------------------
    const coloPowerCost = formData.coloPowerCapacity * formData.coloRate; // Power cost based on capacity and rate
   
    let coloHardwareCost = 0;
    if (formData.coloOutsourceHardware) {
      if (formData.coloServerType === 'gpu') {
        console.log(formData.coloServerType);
        const costPerGPUServer = COST_CONSTANTS.GPU_MODEL_COST[formData.coloGpuModel] || 20000;
        const gpuServersPerRack = formData.coloGpuServersPerRack; 
        const totalGpuServers = formData.coloGPUrackCount * gpuServersPerRack;
        coloHardwareCost = costPerGPUServer * totalGpuServers;
      } else if (formData.coloServerType === 'both') {
        coloHardwareCost = COST_CONSTANTS.RACK_COST.both * (formData.coloCPUrackCount + formData.coloGPUrackCount);
      } else {
        coloHardwareCost = COST_CONSTANTS.RACK_COST.cpu * formData.coloCPUrackCount;
      }
    }
   
    // Optional customization cost for colocation (if specified)
    const customizationCost = formData.customization ? parseFloat(formData.customizationCost || 0) : 0;


    // ---------------------------
    // revenue
    // ---------------------------

    // Revenue calculation inputs
    const years = 5; // Projection for 5 years
    const utilizationRate = 0.8; // 70% utilization
    const hoursPerDay = 24; // Total hours per day
    const daysPerMonth = 30; // Approximation for monthly revenue
    const gpuperserver = 8;
    const deployedGPUs = formData.coloGPUrackCount * formData.coloGpuServersPerRack * gpuperserver;

    let revenueData = [];

    // Check if the gpuModel exists in COST_CONSTANTS
    const gpuModelRates =
      COST_CONSTANTS.GPU_MODELS_RATES_YEARLY[formData.coloGpuModel]?.rates;

    if (!gpuModelRates) {
      throw new Error(`Rates not found for GPU model: ${formData.coloGpuModel}`);
    }

    for (let year = 0; year < years; year++) {
      let yearlyRevenue = 0;

      // Select the rate for the current year or use the last rate if the year exceeds rate array length
      const hourlyRate = gpuModelRates[Math.min(year, gpuModelRates.length - 1)];

      // Calculate revenue for the current GPU model
      const monthlyRevenuePerGPU =
        hourlyRate * utilizationRate * hoursPerDay * daysPerMonth;
      const totalMonthlyRevenue = monthlyRevenuePerGPU * deployedGPUs;
      yearlyRevenue = totalMonthlyRevenue * 12; // Total for the year

      revenueData.push({
        year: 2025 + year,
        gpuModel: formData.gpuModel, // Include the GPU model in the data
        revenue: yearlyRevenue,
      });
    }

    console.log('colo revenueData', revenueData);
   
    // ---------------------------
    // 2) Total CapEx (if applicable)
    // ---------------------------
    const coloTotalCapEx = formData.bringOwnHardware ? coloHardwareCost + customizationCost : customizationCost;

    // 4) Networking
    // ---------------------------
    const netMultiplier = COST_CONSTANTS.Networking_type_multiplier[formData.coloNetworkType] || 1.0;
    const coloNetworkingCost = formData.coloRackCount * COST_CONSTANTS.NETWORK_BASE_PER_RACK * netMultiplier;
   
    // ---------------------------
    // 3) OpEx Calculations
    // ---------------------------
    // Power usage (in kWh) for monthly billing

    const monthlyMaintenanceCost = formData.coloMaintenanceCost ? parseFloat(formData.coloMaintenanceCost) / 12 : (coloTotalCapEx * 0.07) / 12;
    
    const monthlyKWh = formData.coloPowerCapacity * formData.coloPueTarget * 720;
    const monthlyColoPowerCost = monthlyKWh * COST_CONSTANTS.POWER_RATE; // Tracking, not charged directly
   
    const monthlyColoCost = coloPowerCost + customizationCost;
   
    const staffCountMap = { '1-5': 3, '6-10': 8, '10+': 12 };
    const staffCount = staffCountMap[formData.coloItStaffCount] || 3;
    const monthlyLaborCost = staffCount * formData.coloLaborRate * 160;
   
    const coloMonthlyOpEx = monthlyColoCost + monthlyLaborCost;
   
    // ---------------------------
    // Update costs state
    // ---------------------------
    setColoCosts({
      coloTotalCapEx,
      coloMonthlyOpEx,
      coloHardwareCost,
      revenueData,
   
      // For CapEx breakdown
      customizationCost: customizationCost,
      coloServersCost: coloHardwareCost,
      coloNetworkingCost: coloNetworkingCost,
   
      // For OpEx breakdown
      coloPowerCost: monthlyColoCost,
      coloLaborCost: monthlyLaborCost,
      coloMaintenanceCost: monthlyMaintenanceCost,
      coloSoftwareCost: 1500,
   
      // For Power Usage
      coloPowerUsage: monthlyKWh,
    });
    console.log(coloTotalCapEx);
    console.log(coloMonthlyOpEx);
    
  };

  useEffect(calculateColoCosts, [formData]);

  const handleGeneratePDF = () => {
    generatePDF2(formData, coloTableData , coloOpExData, coloCosts);
  };

  const renderColo1 = () => (
    <div className="space-y-2 text-xs">
      {/* Section 3.A: Colo */}
      <section>
        <div className='bg-blue-500 bg-opacity-20 p-1 rounded-lg'>
          <h3 className="text-sm 2xl:text-[1.7rem] font-bold mb-1 2xl:p-2">A: Colo</h3>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 items-center text-xs"> {/* Text size adjusted */}
          <div className="flex items-center">
            <label className="text-xs 2xl:text-[1.5rem] font-bold w-1/3">Colo Provider</label>
            <select
              className="w-2/3 p-1 text-xs 2xl:text-[1.5rem] border rounded bg-gray-500 bg-opacity-10"
              value={formData.coloProvider}
              onChange={(e) =>
                setFormData({ ...formData, coloProvider: e.target.value })
              }
            >
              <option value="Equinix" className="bg-gray-500 ">Equinix</option>
              <option value="Digital Reality" className="bg-gray-500 ">Digital Reality</option>
              <option value="CoreSite" className="bg-gray-500">CoreSite</option>
              <option value="CyprusOne" className="bg-gray-500">CyprusOne</option>
              <option value="Iron Mountain" className="bg-gray-500">Iron Mountain</option>
              <option value="Others" className="bg-gray-500">Others</option>
            </select>
          </div>
          <div>
            <label className="block mb-2 2xl:mt-4">
              <span className="2xl:text-[1.5rem]">Colo Rate ($/kW/month):</span>
              <input
                type="range"
                min="10"
                max="1000"
                step="1"
                value={formData.coloRate}
                onChange={(e) =>
                  setFormData({ ...formData, coloRate: Number(e.target.value) })
                }
                className="mt-1 block w-full text-xs 2xl:text-[1.5rem]" 
              />
              <div className="text-xs 2xl:text-[1.5rem] 2xl:mt-2">{formData.coloRate} $/kW/month</div>
            </label>
          </div>

          <div>
          <label className="block mb-2">
            <span className="text-xs 2xl:text-[1.5rem]">Power Needed (MW):</span>
            <div className="flex items-center gap-2">
              {/* Range Slider */}
              <input
                type="range"
                min="0.1"
                max="1000"
                step="0.1"
                value={formData.coloPowerCapacity / 1000}
                onChange={(e) => {
                  const coloPowerCapacity = Number(e.target.value) * 1000;
                  const coloRackCount = Math.ceil(coloPowerCapacity / formData.coloPowerDensity);

                  // Update CPU or GPU rack count based on serverType
                  if (formData.coloServerType === "cpu") {
                    setFormData({
                      ...formData,
                      coloPowerCapacity,
                      coloCPUrackCount: coloRackCount,
                      coloRackCount,
                    });
                  } else if (formData.coloServerType === "gpu") {
                    setFormData({
                      ...formData,
                      coloPowerCapacity,
                      coloGPUrackCount: coloRackCount,
                      coloRackCount,
                    });
                  }
                }}
                className="mt-1 flex-1"
              />

              {/* Input Field */}
              <input
                type="number"
                min="0.1"
                max="1000"
                step="0.1"
                value={formData.coloPowerCapacity / 1000}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  if (value >= 0.1 && value <= 1000) {
                    const coloPowerCapacity = value * 1000;
                    const coloRackCount = Math.ceil(coloPowerCapacity / formData.coloPowerDensity);

                    // Update CPU or GPU rack count based on serverType
                    if (formData.coloServerType === "cpu") {
                      setFormData({
                        ...formData,
                        coloPowerCapacity,
                        coloCPUrackCount: coloRackCount,
                        coloRackCount,
                      });
                    } else if (formData.coloServerType === "gpu") {
                      setFormData({
                        ...formData,
                        coloPowerCapacity,
                        coloGPUrackCount: coloRackCount,
                        coloRackCount,
                      });
                    }
                  }
                }}
                className="w-24 p-1 text-xs 2xl:text-[1.5rem] border rounded bg-gray-500 bg-opacity-10"
              />
            </div>
            <div className="text-xs 2xl:text-[1.5rem] mt-1">
              {(formData.coloPowerCapacity / 1000).toLocaleString()} MW
            </div>
          </label>
          </div>

          <div className="flex items-center">
            <label className="text-xs 2xl:text-[1.5rem] font-bold w-1/3">Cooling Approach</label>
            <select
              className="w-2/3 p-1 text-xs 2xl:text-[1.5rem] border rounded bg-gray-500 bg-opacity-10"
              value={formData.coloCoolingApproach}
              onChange={(e) =>
                setFormData({ ...formData, coloCoolingApproach: e.target.value })
              }
            >
              <option value="air" className="bg-gray-500 ">Air-cooled</option>
              <option value="liquid" className="bg-gray-500 ">Liquid-cooled</option>
              <option value="hybrid" className="bg-gray-500">Hybrid</option>
            </select>
          </div>


          <div className="flex items-center 2xl:mt-1">
            <label className="text-xs 2xl:text-[1.5rem] font-bold w-1/3">Air Distribution Type</label>
            <select
              className="w-2/3 p-1 text-xs 2xl:text-[1.5rem] border rounded bg-gray-500 bg-opacity-10"
              value={formData.coloAirDistributionType}
              onChange={(e) =>
                setFormData({ ...formData, coloAirDistributionType: e.target.value })
              }
            >
              <option value="hotColdContainment" className="bg-gray-500">Hot-Aisle/Cold-Aisle Containment</option>
              <option value="rowBased" className="bg-gray-500">Row-Based Cooling</option>
              <option value="perimeter" className="bg-gray-500">Perimeter Cooling</option>
              <option value="other" className="bg-gray-500">Other / Custom</option>
            </select>
          </div>

          <div className="flex items-center">
            <label className="text-xs 2xl:text-[1.5rem] font-bold w-1/3">UPS Style</label>
            <select
              className="w-2/3 p-1 text-xs 2xl:text-[1.5rem] border rounded bg-gray-500 bg-opacity-10"
              value={formData.coloUpsStyle}
              onChange={(e) =>
                setFormData({ ...formData, coloUpsStyle: e.target.value })
              }
            >
              <option value="onlineDoubleConversion" className="bg-gray-500">Online Double-Conversion</option>
              <option value="lineInteractive" className="bg-gray-500">Line-Interactive</option>
              <option value="offline" className="bg-gray-500">Offline/Standby</option>
              <option value="other" className="bg-gray-500">Other</option>
            </select>
          </div>

          <div className="flex items-center 2xl:mt-1 2xl:mb-1">
            <label className="text-xs 2xl:text-[1.5rem] 2xl:leading-[1.5rem] font-bold w-1/3 pr-1">Power Distribution Type</label>
            <select
              className="w-2/3 p-1 text-xs 2xl:text-[1.5rem] border rounded bg-gray-500 bg-opacity-10"
              value={formData.coloPowerDistributionType}
              onChange={(e) =>
                setFormData({ ...formData, coloPowerDistributionType: e.target.value })
              }
            >
              <option value="basicPanelboards" className="bg-gray-500">Basic Wall-Mount Panelboards</option>
              <option value="busway" className="bg-gray-500">Busway / Overhead Distribution</option>
              <option value="floorPDU" className="bg-gray-500">Floor PDU boxes</option>
              <option value="other" className="bg-gray-500">Other</option>
            </select>
          </div>
        </div>
      </section>

      {/* Section 3.B: Hardware  and Software & Licensing*/}
      <section className="2xl:pt-2">
        <div className='bg-blue-500 bg-opacity-20 p-1 rounded-lg'>
          <h3 className="text-sm 2xl:text-[1.7rem] font-bold mb-1 2xl:p-2">B: Hardware and Software & Licensing</h3>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 items-center text-xs"> {/* Text size adjusted */}
          <div className="2xl:mt-2">
              {/* Number of Racks */}
              <label className="block mb-2">
                <span className="2xl:text-[1.5rem]">Number of Racks:</span>
                <div className="flex items-center gap-4">
                  {/* Rack Count Display */}
                  <div className="text-xs 2xl:text-[1.5rem] bg-gray-500 bg-opacity-10 rounded px-2 py-1">
                    {formData.serverType === "cpu"
                      ? `${formData.coloCPUrackCount} racks`
                      : `${formData.coloGPUrackCount} racks`}
                  </div>

                  {/* GPU Servers per Rack */}
                  <label className="block">
                    <span className="2xl:text-[1.5rem]">GPU Servers per Rack:</span>
                    <input
                      type="number"
                      min="1"
                      value={formData.gpuServersPerRack || 8} 
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          gpuServersPerRack: Number(e.target.value),
                        })
                      }
                      className="w-16 p-1 text-xs 2xl:text-[1.5rem] border rounded bg-gray-500 bg-opacity-10 ml-4"
                    />
                  </label>
                </div>
              </label>


              {/* Server Type */}
              <div className="2xl:pt-3">
                <label className="block 2xl:text-[1.5rem]">Server Type:</label>
                {['CPU-only servers', 'GPU/HPC nodes', 'Mix of both'].map((type) => (
                  <label key={type} className="inline-flex items-center mr-2 2xl:pt-4">
                    <input
                      type="checkbox"
                      className="form-checkbox text-xs 2xl:text-[1.5rem]"
                      checked={
                        (type === 'CPU-only servers' && formData.coloServerType === 'cpu') ||
                        (type === 'GPU/HPC nodes' && formData.coloServerType === 'gpu') ||
                        (type === 'Mix of both' && formData.coloServerType === 'both')
                      }
                      onChange={() => {
                        let serverTypeValue;
                        let rackCountValue;

                        if (type === 'CPU-only servers') {
                          serverTypeValue = 'cpu';
                          rackCountValue = formData.coloCPUrackCount;
                        } else if (type === 'GPU/HPC nodes') {
                          serverTypeValue = 'gpu';
                          rackCountValue = formData.coloGPUrackCount;
                        } else if (type === 'Mix of both') {
                          serverTypeValue = 'both';
                          rackCountValue = Math.max(
                            formData.coloCPUrackCount,
                            formData.coloGPUrackCount
                          ); // Example: Mix logic could vary
                        }

                        setFormData({
                          ...formData,
                          coloServerType: serverTypeValue,
                          coloRackCount: rackCountValue,  
                        });
                      }}
                    />
                    <span className="ml-1 text-xs 2xl:text-[1.5rem]">{type}</span>
                  </label>
                ))}
              </div>
            </div>
          <div>
          <label className="block mb-2">
            <span className="2xl:text-[1.5rem]">Power Density (kW/rack):</span>
            <input
              type="range"
              min="1"
              max="150"
              step="1"
              value={formData.coloPowerDensity}
              onChange={(e) => {
                const coloPowerDensity = Number(e.target.value);
                const coloRackCount = Math.ceil(formData.coloPowerCapacity / coloPowerDensity);

                // Update CPU or GPU rack count based on serverType
                if (formData.coloServerType === "cpu") {
                  setFormData({
                    ...formData,
                    coloPowerDensity,
                    coloCPUrackCount: coloRackCount,
                    coloRackCount,
                  });
                } else if (formData.coloServerType === "gpu") {
                  setFormData({
                    ...formData,
                    coloPowerDensity,
                    coloGPUrackCount: coloRackCount,
                    coloRackCount,
                  });
                }
              }}
              className="mt-1 block w-full text-xs 2xl:text-[1.5rem]"
            />
            <div className="text-xs 2xl:text-[1.5rem] 2xl:mt-2">
              {formData.coloPowerDensity} kW/rack
            </div>
          </label>
          </div>
          {/* Conditional Rendering for GPU Brand and GPU Model */}
          {formData.coloServerType === 'gpu' && (
            <>
              <div>
                <label className="block mb-2 2xl:mt-3">
                  <span className="2xl:text-[1.5rem]">GPU Brand</span>
                  <select
                    className="mt-1 block w-full p-1 text-xs 2xl:text-[1.5rem] border rounded bg-gray-500 bg-opacity-10"
                    value={formData.coloGpuBrand}
                    onChange={(e) =>
                      setFormData({ ...formData, coloGpuBrand: e.target.value })
                    }
                  >
                    <option value="NVIDIA" className="bg-gray-500">NVIDIA</option>
                  </select>
                </label>
              </div>

              <div>
                <label className="block mb-2">
                  <span className="2xl:text-[1.5rem]">GPU Model</span>
                  <select
                    className="mt-1 block w-full p-1 text-xs 2xl:text-[1.5rem] border rounded bg-gray-500 bg-opacity-10"
                    value={formData.coloGpuModel} 
                    onChange={(e) =>
                      setFormData({ ...formData, coloGpuModel: e.target.value })
                    }
                  >
                    <option value="A100_PCIe" className="bg-gray-500">A100-PCIe</option>
                    <option value="A100_SXM" className="bg-gray-500">A100-SXM</option>
                    <option value="H100_PCIe" className="bg-gray-500">H100-PCIe</option>
                    <option value="H100_SXM" className="bg-gray-500">H100-SXM</option>
                    <option value="H200" className="bg-gray-500">H200</option>
                  </select>
                </label>
              </div>
            </>
          )}
          <div>
            <label className="block 2xl:text-[1.5rem] 2xl:leading-[2rem]">Networking:</label>
            {['Basic Ethernet (1/10/25 GbE)', 'High-speed interconnect', 'Mix of both'].map(
              (networkType) => ( 
                <label key={networkType} className="inline-flex items-center mr-2">
                  <input
                    type="checkbox"
                    className="form-checkbox text-xs 2xl:text-[1.5rem]"
                    checked={
                      (networkType === 'Basic Ethernet (1/10/25 GbE)' && formData.coloNetworkType === 'Basic_Ethernet') ||
                      (networkType === 'High-speed interconnect' && formData.coloNetworkType === 'High_speed_interconnect') ||
                      (networkType === 'Mix of both' && formData.coloNetworkType === 'Both')
                    }
                    onChange={() => {
                      let serverTypeValue;
                      if (networkType === 'Basic Ethernet (1/10/25 GbE)') serverTypeValue = 'Basic_Ethernet';
                      else if (networkType === 'High-speed interconnect') serverTypeValue = 'High_speed_interconnect';
                      else if (networkType === 'Mix of both') serverTypeValue = 'Both';

                      setFormData({ ...formData, coloNetworkType: serverTypeValue });
                    }}
                  />
                  <span className="ml-1 text-xs 2xl:text-[1.5rem] 2xl:leading-[2rem]">{networkType}</span>
                </label>
              )
            )}
          </div>
          
          
          <div>
            <div className="flex items-center py-1">
              <div className="w-1/3 ">
              <label className="block font-bold 2xl:text-[1.5rem] 2xl:leading-[2rem]">Operating System</label>
              </div>
              <select
                className="w-2/3 p-1 text-xs 2xl:text-[1.5rem] border rounded bg-gray-500 bg-opacity-10"
                value={formData.coloOperatingSystem}
                onChange={(e) =>
                  setFormData({ ...formData, coloOperatingSystem: e.target.value })
                }
              >
                <option value="linux" className="bg-gray-500">Linux (Ubuntu, RHEL, Rocky, etc.)</option>
                <option value="windows" className="bg-gray-500">Windows</option>
                <option value="other" className="bg-gray-500">Other HPC distro</option>
              </select>
            </div>
            <div className="flex items-center">
              <div className="w-1/3">
              <label className="block font-bold 2xl:text-[1.5rem] 2xl:leading-[2rem]">HPC Software</label>
              </div>
              <select
                className="w-2/3 p-1 text-xs 2xl:text-[1.5rem] border rounded bg-gray-500 bg-opacity-10"
                value={formData.coloHpcSoftware}
                onChange={(e) =>
                  setFormData({ ...formData, coloHpcSoftware: e.target.value })
                }
              >
                <option value="none" className="bg-gray-500">None</option>
                <option value="slurm" className="bg-gray-500">Slurm</option>
                <option value="pbs" className="bg-gray-500">PBS</option>
                <option value="lsf" className="bg-gray-500">LSF</option>
                <option value="gridEngine" className="bg-gray-500">Grid Engine</option>
                <option value="other" className="bg-gray-500">Other HPC Scheduler</option>
              </select>
            </div>
          </div>
        </div>
      </section>
  
      {/* Section 4: Staffing & Maintenance */}
      <section>
        <div className='bg-blue-500 bg-opacity-20 p-1 rounded-lg'>
          <h3 className="text-sm 2xl:text-[1.5rem] font-bold mb-1 2xl:p-2">C: Staffing & Maintenance</h3>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 items-center text-xs 2xl:pt-2"> {/* Text size adjusted */}
          <div>
            <label className="block font-bold 2xl:text-[1.5rem] 2xl:leading-[2rem]">Labor Rate ($/hr)</label>
            <input
              type="number"
              className="w-full p-1 text-xs 2xl:text-[1.5rem] border rounded bg-gray-500 bg-opacity-10"
              placeholder="$38"
              value={formData.coloLaborRate}
              onChange={(e) =>
                setFormData({ ...formData, coloLaborRate: Number(e.target.value) })
              }
            />
          </div>
          <div>
            <label className="block font-bold 2xl:text-[1.5rem] 2xl:leading-[2rem]">IT Staff Count</label>
            <select
              className="w-full p-1 text-xs 2xl:text-[1.5rem] border rounded bg-gray-500 bg-opacity-10"
              value={formData.coloItStaffCount}
              onChange={(e) =>
                setFormData({ ...formData, coloItStaffCount: e.target.value })
              }
            >
              <option value="1-5" className="bg-gray-500">1–5</option>
              <option value="6-10" className="bg-gray-500">6–10</option>
              <option value="10+" className="bg-gray-500">10+</option>
            </select>
          </div>
          <div>
            <label className="block font-bold 2xl:text-[1.5rem] 2xl:leading-[2rem]">Facilities Staff Needed?</label>
            <div className="flex items-center">
              <input
                type="checkbox"
                className="form-checkbox text-xs 2xl:text-[1.5rem]"
                checked={formData.coloFacilitiesStaff}
                onChange={() =>
                  setFormData({
                    ...formData,
                    coloFacilitiesStaff: !formData.coloFacilitiesStaff,
                  })
                }
              />
              <span className="ml-1 text-xs 2xl:text-[1.5rem]">Yes</span>
            </div>
            {formData.coloFacilitiesStaff && (
              <div className="mt-2">
                <label className="block font-bold 2xl:text-[1.5rem] 2xl:leading-[2rem]">Number of Facilities Staff</label>
                <input
                  type="number"
                  className="w-full p-1 text-xs 2xl:text-[1.5rem]   border rounded bg-gray-500 bg-opacity-10"
                  value={formData.coloFacilitiesStaffCount}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      coloFacilitiesStaffCount: Number(e.target.value),
                    })
                  }
                />
              </div>
            )}
          </div>
          <div>
            <label className="block font-bold 2xl:text-[1.5rem] 2xl:leading-[2rem]">Outsource Hardware Maintenance?</label>
            <div className="flex items-center">
              <input
                type="checkbox"
                className="form-checkbox text-xs 2xl:text-[1.5rem]"
                checked={formData.coloOutsourceHardware}
                onChange={() =>
                  setFormData({
                    ...formData,
                    coloOutsourceHardware: !formData.coloOutsourceHardware,
                  })
                }
              />
              <span className="ml-1 text-xs 2xl:text-[1.5rem]">Yes</span>
            </div>
            {formData.coloOutsourceHardware && (
              <div className="mt-2">
                <label className="block font-bold 2xl:text-[1.5rem] 2xl:leading-[2rem]">Approx. Annual Contract Cost</label>
                <input
                  type="number"
                  className="w-full p-1 text-xs 2xl:text-[1.5rem] border rounded bg-gray-500 bg-opacity-10"
                  placeholder="Enter amount eg $300K/year"
                  value={formData.coloMaintenanceCost}
                  onChange={(e) =>
                    setFormData({ ...formData, coloMaintenanceCost: e.target.value })
                  }
                />
              </div>
            )}
          </div>
        </div>
      </section>

      {/* Section 5: Additional Details */}
      <section>
        <div className='bg-blue-500 bg-opacity-20 p-1 rounded-lg'>
          <h3 className="text-sm 2xl:text-[1.5rem] 2xl:leading-[2rem] font-bold mb-1">E: Additional Considerations</h3>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 items-center text-xs"> {/* Text size adjusted */}
          <div>
            <label className="block 2xl:text-[1.5rem] 2xl:leading-[2rem]">Future Expansion Planned?</label>
            <div className="flex items-center">
              <input
                type="checkbox"
                className="form-checkbox text-xs 2xl:text-[1.5rem]"
                checked={formData.coloExpansion}
                onChange={() =>
                  setFormData({ ...formData, coloExpansion: !formData.coloExpansion })
                }
              />
              <span className="ml-1 text-xs 2xl:text-[1.5rem]">Yes</span>
            </div>
            {formData.coloExpansion && (
              <div className="mt-2">
                <label className="block font-bold 2xl:text-[1.5rem] 2xl:leading-[2rem]">Approximate Timeline</label>
                <select
                  className="w-full p-1 text-xs 2xl:text-[1.5rem] 2xl:leading-[2rem] border rounded bg-gray-500 bg-opacity-10"
                  value={formData.coloExpansionTimeline}
                  onChange={(e) =>
                    setFormData({ ...formData, coloExpansionTimeline: e.target.value })
                  }
                >
                  <option value="1_2years" className="bg-gray-500">1–2 years</option>
                  <option value="3_5years" className="bg-gray-500">3–5 years</option>
                  <option value="5_plusyears" className="bg-gray-500">5+ years</option>
                </select>
              </div>
            )}
          </div>
          <div>
            <label className="block 2xl:text-[1.5rem] 2xl:leading-[2rem]">Environmental / Efficiency Goals?</label>
            <div className="flex items-center">
              <input
                type="checkbox"
                className="form-checkbox text-xs 2xl:text-[1.5rem]"
                checked={formData.coloEnvironmentalGoals}
                onChange={() =>
                  setFormData({
                    ...formData,
                    coloEnvironmentalGoals: !formData.coloEnvironmentalGoals,
                  })
                }
              />
              <span className="ml-1 text-xs 2xl:text-[1.5rem]">Yes</span>
            </div>
            {formData.coloEnvironmentalGoals && (
              <div className="mt-2">
                <label className="block font-bold 2xl:text-[1.5rem] 2xl:leading-[2rem]">Target PUE or Renewable Energy Goal</label>
                <input
                  type="text"
                  className="w-full p-1 border rounded text-xs 2xl:text-[1.5rem] 2xl:leading-[2rem] bg-gray-500 bg-opacity-10"
                  placeholder="Enter target"
                  value={formData.coloPueTarget}
                  onChange={(e) =>
                    setFormData({ ...formData, coloPueTarget: e.target.value })
                  }
                />
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d'];

  const coloCapExData = [
    { name: 'Servers', value: coloCosts.coloServersCost },
    { name: 'Networking', value: coloCosts.coloNetworkingCost },
  ];

  const coloOpExData = [
    { name: 'Colo', value: coloCosts.coloPowerCost },
    { name: 'Labor', value: coloCosts.coloLaborCost },
    { name: 'Maintenance', value: coloCosts.coloMaintenanceCost},
    { name: 'Software', value: coloCosts.coloSoftwareCost },
    { name: 'Other', value: coloCosts.coloOtherCost }
  ];

// Calculate Colo OpEx and Revenue Data for Analysis
const calculateColoOpExAndRevenueData = () => {
  const baseYear = 2025;
  let years = 0;
  const expansionTimeline = formData.coloExpansionTimeline;

  if (expansionTimeline === '1_2years') {
    years = 3;
  } else if (expansionTimeline === '3_5years') {
    years = 5;
  } else if (expansionTimeline === '5_plusyears') {
    years = 7;
  }

  const annualOpExGrowth = 1.07; // 7% annual increase

  // Map revenueData to an object for quick lookup
  const revenueMap = coloCosts.revenueData.reduce((acc, item) => {
    acc[item.year] = item.revenue;
    return acc;
  }, {});

  return Array.from({ length: years }, (_, index) => {
    const year = baseYear + index;
    const yearlyOpEx = coloCosts.coloMonthlyOpEx * 12 * Math.pow(annualOpExGrowth, index);

    return {
      year: year.toString(),
      OpEx: yearlyOpEx,
      Revenue: revenueMap[year] || 0, // Use revenue from revenueData or default to 0
    };
  });
};

const coloOpExAndRevenueData = calculateColoOpExAndRevenueData();

// Calculate CapEx Data
const calculateColoCapExData = () => {
  const baseYear = 2025;
  let years = 0;
  const expansionTimeline = formData.coloExpansionTimeline;

  if (expansionTimeline === '1_2years') {
    years = 3;
  } else if (expansionTimeline === '3_5years') {
    years = 5;
  } else if (expansionTimeline === '5_plusyears') {
    years = 7;
  }

  return Array.from({ length: years }, (_, index) => {
    const year = baseYear + index;
    return {
      year: year.toString(),
      CapEx: index === 0 ? coloCosts.coloTotalCapEx.toLocaleString() : 0 
    };
  });
};

// Merge Revenue, OpEx, and CapEx Data
const generateColoTableData = () => {
  const capExData = calculateColoCapExData();
  return coloOpExAndRevenueData.map((item, index) => ({
    year: item.year,
    Revenue: item.Revenue,
    OpEx: item.OpEx,
    CapEx: capExData[index]?.CapEx || 0, // Use CapEx for the corresponding year
  }));
};

const coloTableData = generateColoTableData();


  // Calculate domain for y-axis
  const getYAxisDomain = () => {
    const opExValues = coloOpExAndRevenueData.flatMap(item => [item.OpEx, item.Revenue]);
    const minValue = Math.min(...opExValues);
    const maxValue = Math.max(...opExValues);
    const padding = (maxValue - minValue) * 0.1; // Add 10% padding

    return [minValue - padding, maxValue + padding];
  };
  // Function to format numbers with appropriate suffixes
  const formatYAxisValue = (value) => {
    if (Math.abs(value) >= 1000000) {
      return `$${(value / 1000000).toFixed(1)}M`;
    } else if (Math.abs(value) >= 1000) {
      return `$${(value / 1000).toFixed(1)}K`;
    }
    return `$${value.toFixed(0)}`;
  };

  // Custom label for the donut charts
  const renderCustomizedLabel = (data) => ({ cx, cy, midAngle, outerRadius, percent, index }) => {
    const RADIAN = Math.PI / 180;
    const radius = outerRadius + 30; // Position the label further outside the pie
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    let y = cy + radius * Math.sin(-midAngle * RADIAN);
  
    // Avoid collision by adjusting y position if labels overlap
    if (index > 0 && y === previousLabelY) {
      y += window.innerWidth >= 2560 ? 40 : 15; 
    }
    previousLabelY = y; // Update the last label position for comparison
  
    return (
      <text
        x={x}
        y={y}
        fill="#f3f4f6"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
        fontSize={window.innerWidth >= 2560 ? '1.5rem' : '12px'}
      >
        {`${data[index].name} (${(percent * 100).toFixed(0)}%)`}
      </text>
    );
  };
  let previousLabelY = null;

  function convertToInteger(value) {
    // Check if the value is already an integer
    if (typeof value === 'number') {
      return value; // Return as-is if it's already an integer
    }
  
    // If it's a string, process it
    if (typeof value === 'string') {
      // Check if there are commas
      if (value.includes(',')) {
        return parseInt(value.replace(/,/g, ''), 10); // Remove commas and convert
      } else {
        return parseInt(value, 10); // Convert directly if no commas
      }
    }
  
    // If the input is not a string or number, return NaN
    return NaN;
  }
    // Calculate Profit Data
const calculateProfitData = () => {
  return coloTableData.map((row, index) => {
    return {
      year: row.year,
      Profit: convertToInteger(row.Revenue) - (convertToInteger(row.CapEx) + convertToInteger(row.OpEx))
    };
  });
};

const coloProfit = calculateProfitData();


  return (
    <div>
      <div className=''>
         <button 
              onClick={() => {
                if (window.innerWidth >= 1024) {
                  setIsExpanded(true);
                } else {
                  setActiveView('results');
                }
            
                // Use a timeout to ensure the UI updates before generating the PDF
                setTimeout(() => {
                  handleGeneratePDF();
                }, 1000);
                
              }}
              className="w-full lg:w-auto flex items-center justify-center gap-2 bg-[#2887BF] hover:bg-blue-700 text-white px-4 py-1 rounded"
            >
              <Download className="w-4 h-4" />
              <span className="text-center">Download Configuration</span>
         </button>
      </div>
      <div className="relative flex flex-col lg:flex-row items-stretch gap-3">  
        <div className="flex flex-row items-center justify-between py-2 lg:hidden gap-2">
              <button
                onClick={() => setActiveView('questionnaire')}
                className={`w-full px-4 py-2 rounded-lg font-medium transition-all bg-[#2887BF] ${
                  activeView === 'questionnaire'
                    ? 'bg-[#1b648f] text-white'
                    : 'text-gray-100 hover:bg-blue-500/20'
                }`}
              >
                Questionnaire
              </button>
              <button
                onClick={() => setActiveView('results')}
                className={`w-full px-4 py-2 rounded-lg font-medium transition-all bg-[#2887BF] ${
                  activeView === 'results'
                    ? 'bg-[#1b648f] text-white'
                    : 'text-gray-100 hover:bg-blue-500/20'
                }`}
              >
                Results
              </button>
        </div>
        {/* Background Overlay */}
        {isExpanded && (
          <div
            className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 backdrop-blur-sm"
            style={{ zIndex: 40 }}
            onClick={() => setIsExpanded(false)}
          ></div>
        )}
        {/* Main Content */}
        <div className={`w-full lg:w-[60%] bg-blue-500 bg-opacity-30 rounded-lg shadow-lg p-6 ${activeView === 'questionnaire' ? 'block' : 'hidden lg:block'}`}>
          {renderColo1()}
        </div>

        {/* Results Container */}
        <div 
          className={`
            relative lg:absolute 
            transition-all duration-500 ease-in-out 
            rounded-lg shadow-lg p-6 
            text-gray-100
            lg:mt-0
            ${activeView === 'results' ? 'block' : 'hidden lg:block'}
            ${window.innerWidth >= 1024 ? (
              isExpanded 
                ? 'lg:w-[95%] right-0 bg-[#0a1733] bg-opacity-90 backdrop-blur-sm' 
                : 'lg:w-[39%] right-0 bg-blue-500 bg-opacity-30'
            ) : 'bg-blue-500 bg-opacity-30'}
          `}
          style={{ 
            zIndex: isExpanded ? 50 : 1,
            height: '100%',
            overflow: isExpanded ? 'auto' : 'visible'
          }}
        >
          {/* Toggle Button */}
          {window.innerWidth >= 1024 && (
                  <button
                    onClick={() => setIsExpanded(!isExpanded)}
                    className="absolute -left-4 top-1/2 transform -translate-y-1/2 bg-blue-500 rounded-full p-1 ml-4 text-white hover:bg-blue-600 transition-colors z-50 cursor-pointer shadow-lg hidden lg:block"
                  >
                    {isExpanded ? <ChevronRight size={24} /> : <ChevronLeft size={24} />}
                  </button>
                )}

          <div className={`grid ${isExpanded && window.innerWidth >= 1024 ? 'grid-cols-2' : 'grid-cols-1'} gap-4`}>
            {/* Original Charts Column */}
            <div>
              <h2 className="text-xl 2xl:text-[1.5rem] 2xl:leading-[2rem] font-bold mb-2">Results</h2>
              
              {/* Yearly Financial Summary */}
              <div id="coloYearlyFinancialSummaryTable" className="h-auto 2xl:h-auto mb-5">
                <div className="bg-blue-500 bg-opacity-20 rounded-lg mb-5">
                  <div className="flex justify-between items-center px-3 py-1">
                    <h3 className="text-lg 2xl:text-[1.5rem] font-semibold">Yearly Financial Summary</h3>
                  </div>
                </div>

                <div className="overflow-x-auto">
                  <table className="min-w-full rounded-lg text-left">
                    <thead>
                      <tr className="bg-blue-300 bg-opacity-30">
                        <th className="py-2 px-2 text-xs 2xl:text-[1.5rem]">Year</th>
                        <th className="py-2 px-2 text-xs 2xl:text-[1.5rem]">Revenue ($)</th>
                        <th className="py-2 px-2 text-xs 2xl:text-[1.5rem]">CapEx ($)</th>
                        <th className="py-2 px-2 text-xs 2xl:text-[1.5rem]">OpEx ($)</th>
                        <th className="py-2 px-2 text-xs 2xl:text-[1.5rem]">Profit ($)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {[...coloTableData, {
                        year: 'Total',
                        Revenue: coloTableData.reduce((sum, row) => sum + convertToInteger(row.Revenue), 0),
                        CapEx: coloTableData.reduce((sum, row) => sum + convertToInteger(row.CapEx), 0),
                        OpEx: coloTableData.reduce((sum, row) => sum + convertToInteger(row.OpEx), 0)
                      }].map((row, index) => {
                        const isTotal = row.year === 'Total';
                        const profit = isTotal 
                          ? row.Revenue - (row.CapEx + row.OpEx)
                          : convertToInteger(row.Revenue) - (convertToInteger(row.CapEx) + convertToInteger(row.OpEx));
                        
                        return (
                          <tr
                            key={index}
                            className={isTotal 
                              ? "bg-blue-500 bg-opacity-50 font-bold" 
                              : (index % 2 === 0 ? "bg-blue-500 bg-opacity-30" : "bg-blue-400 bg-opacity-30")
                            }
                          >
                            <td className="py-2 px-2 text-xs 2xl:text-[1.5rem]">{row.year}</td>
                            <td className="py-2 px-2 text-xs font-bold 2xl:text-[1.5rem] text-green-500">
                              ${isTotal ? row.Revenue.toLocaleString() : row.Revenue.toLocaleString()}
                            </td>
                            <td className="py-2 px-2 text-xs font-bold 2xl:text-[1.5rem] text-red-500">
                              ${isTotal ? row.CapEx.toLocaleString() : row.CapEx.toLocaleString()}
                            </td>
                            <td className="py-2 px-2 text-xs font-bold 2xl:text-[1.5rem] text-red-500">
                              ${isTotal ? row.OpEx.toLocaleString() : row.OpEx.toLocaleString()}
                            </td>
                            <td className={`py-2 px-2 text-xs font-bold 2xl:text-[1.5rem] ${
                              profit >= 0 ? 'text-green-500' : 'text-red-500'
                            }`}>
                              ${(profit).toLocaleString()}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

                {/* Profit Calculation */}
                <div className="mt-3 p-1 bg-blue-500 bg-opacity-30 rounded-lg">
                  <h4 className={`text-lg 2xl:text-[1.5rem] font-semibold ${
                      coloTableData.reduce((acc, row) => {
                        return acc + (convertToInteger(row.Revenue) - (convertToInteger(row.CapEx) + convertToInteger(row.OpEx)));
                      }, 0) >= 0 ? 'text-green-500' : 'text-red-500'
                    }`}>
                    Net Profit = $
                    {coloTableData.reduce((acc, row) => {
                      return acc + (convertToInteger(row.Revenue) - (convertToInteger(row.CapEx) + convertToInteger(row.OpEx)));
                    }, 0).toLocaleString()}
                  </h4>
                </div>
              </div>

              {/* OpEx Analysis with Revenue Line */}
              <div id="coloOpExRevenueChart" className="h-48 2xl:h-56 mb-5">
                  <div className="flex flex-row justify-between px-3 bg-blue-500 bg-opacity-20 rounded-lg pt-1 mb-2">
                    <h3 className="text-lg 2xl:text-[1.5rem] 2xl:leading-[2rem] font-semibold">OpEx Vs Revenue Analysis</h3>
                  </div>
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={coloOpExAndRevenueData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="year" padding={{ left: 20, right: 20 }} />
                      <YAxis 
                        domain={getYAxisDomain()}
                        tickCount={8} 
                        tickFormatter={formatYAxisValue}
                        width={100} 
                      />
                      <Tooltip 
                        formatter={(value) => [`$${value.toFixed(2)}`, '']}
                        labelFormatter={(label) => `Year: ${label}`} 
                        contentStyle={{ color: '#000' }} 
                        itemStyle={{ color: '#000' }} 
                      />
                      <Legend />
                      <Line 
                        type="monotone" 
                        dataKey="OpEx" 
                        stroke="#82ca9d" 
                        name="Annual OpEx" 
                        strokeWidth={2} 
                        dot={{ r: 4 }} 
                        activeDot={{ r: 6 }} 
                      />
                      <Line 
                        type="monotone" 
                        dataKey="Revenue" 
                        stroke="#8884d8" 
                        name="Annual Revenue" 
                        strokeWidth={2} 
                        dot={{ r: 4 }} 
                        activeDot={{ r: 6 }} 
                      />
                    </LineChart>
                  </ResponsiveContainer>
              </div>

              <div id="coloProfitAnalysis" className="hidden 2xl:block">
                  {/* Profit Analysis */}
                <div className="h-48 2xl:h-56 mb-5">
                    <div className="flex flex-row justify-between px-3 bg-blue-500 bg-opacity-20 rounded-lg pt-1 mb-2">
                      <h3 className="text-lg 2xl:text-[1.5rem] 2xl:leading-[2rem] font-semibold">Annual Profit Analysis</h3>
                    </div>
                    <ResponsiveContainer width="100%" height="100%">
                      <LineChart data={coloProfit}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="year" padding={{ left: 20, right: 20 }} />
                        <YAxis 
                          domain={getYAxisDomain()}
                          tickCount={8} 
                          tickFormatter={formatYAxisValue}
                          width={100} 
                        />
                        <Tooltip 
                          formatter={(value) => [`$${value.toFixed(2)}`, '']}
                          labelFormatter={(label) => `Year: ${label}`} 
                          contentStyle={{ color: '#000' }} 
                          itemStyle={{ color: '#000' }} 
                        />
                        <Legend />
                        <Line 
                          type="monotone" 
                          dataKey="Profit" 
                          stroke="#ffa500" 
                          name="Annual Profit" 
                          strokeWidth={2} 
                          dot={{ r: 4 }} 
                          activeDot={{ r: 6 }} 
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
              </div>
            </div>

            {/* Hidden Charts Column - Only visible when expanded */}
            
              <div className={`
                block 
                ${!isExpanded && 'lg:hidden'}
                transition-opacity duration-1000
              `}>
                <h2 className="text-xl 2xl:text-[1.5rem] 2xl:leading-[2rem] font-bold mb-2">Additional Analysis</h2>

                {/* CapEx Breakdown */}
                <div id="coloCapExBreakdown" className="h-48 lg:h-48 2xl:h-56 mb-5">
                  <div className="bg-blue-500 bg-opacity-20 rounded-lg">
                    <div className="flex flex-row justify-between items-center px-3 py-1">
                      <h3 className="text-lg 2xl:text-[1.5rem]">Total CapEx: ${coloCosts.coloTotalCapEx.toLocaleString()}</h3>
                      <h3 className="text-lg font-semibold 2xl:text-[1.5rem]">CapEx Breakdown</h3>
                    </div>
                  </div>
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={coloCapExData}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        innerRadius={window.innerWidth >= 2560 ? 30 : 20}
                        outerRadius={window.innerWidth >= 2560 ? 70 : 50}
                        labelLine={{ strokeWidth: 1.5 }}
                          label={(props) => (
                            <text
                              x={props.x}
                              y={props.y}
                              fill={COLORS[props.index % COLORS.length]} 
                              textAnchor={props.textAnchor}
                              fontSize={12}
                            >
                              {`${props.name} (${(props.percent * 100).toFixed(1)}%)`}
                            </text>
                          )}
                      >
                        {coloCapExData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip formatter={(value) => `$${(value / 1000000).toFixed(1)}M`} />
                      <Legend verticalAlign="bottom" align="center" layout="horizontal" />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
                
                {/* OpEx Table */}
                <div id="coloOpExBreakdown" className="h-auto 2xl:h-auto mb-5 pt-4 lg:pt-0">
                  <div className="bg-blue-500 bg-opacity-20 rounded-lg mb-5">
                    <div className="flex flex-row justify-between items-center px-3 py-1">
                      <h3 className="text-lg 2xl:text-[1.5rem] 2xl:leading-[2rem]">
                        Monthly OpEx: ${coloCosts?.coloMonthlyOpEx?.toLocaleString() || 0}
                      </h3>
                      <h3 className="text-lg 2xl:text-[1.5rem] 2xl:leading-[2rem] font-semibold">
                        OpEx Breakdown
                      </h3>
                    </div>
                  </div>

                  {/* Table */}
                  <div className="overflow-x-auto mt-3">
                    <table className="min-w-full rounded-lg text-left">
                      <thead>
                        <tr className="bg-blue-300 bg-opacity-30">
                          <th className="py-2 px-4 text-xs 2xl:text-[1.5rem]">Name</th>
                          <th className="py-2 px-4 text-xs 2xl:text-[1.5rem]">Value ($)</th>
                          <th className="py-2 px-4 text-xs 2xl:text-[1.5rem]">Percentage (%)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {coloOpExData?.map((item, index) => {
                          const value = item.value || 0;
                          const total = coloCosts?.coloMonthlyOpEx || 1; // Avoid division by zero
                          const percentage = ((value / total) * 100).toFixed(2); // Calculate percentage

                          return (
                            <tr
                              key={index}
                              className={`${
                                index % 2 === 0 ? "bg-blue-500 bg-opacity-30" : "bg-blue-400 bg-opacity-30"
                              }`}
                            >
                              <td className="py-2 px-4 text-xs 2xl:text-[1.5rem]">{item.name}</td>
                              <td className="py-2 px-4 text-xs 2xl:text-[1.5rem]">
                                ${value.toLocaleString()}
                              </td>
                              <td className="py-2 px-4 text-xs 2xl:text-[1.5rem]">
                                {percentage}%
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>

                {/* Profit Analysis */}
                <div id="coloProfitAnalysis" className="h-48 2xl:h-56 mb-5 2xl:hidden block">
                    <div className="flex flex-row justify-between px-3 bg-blue-500 bg-opacity-20 rounded-lg pt-1 mb-2">
                      <h3 className="text-lg 2xl:text-[1.5rem] 2xl:leading-[2rem] font-semibold">Annual Profit Analysis</h3>
                    </div>
                    <ResponsiveContainer width="100%" height="100%">
                      <LineChart data={coloProfit}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="year" padding={{ left: 20, right: 20 }} />
                        <YAxis 
                          domain={getYAxisDomain()}
                          tickCount={8} 
                          tickFormatter={formatYAxisValue}
                          width={100} 
                        />
                        <Tooltip 
                          formatter={(value) => [`$${value.toFixed(2)}`, '']}
                          labelFormatter={(label) => `Year: ${label}`} 
                          contentStyle={{ color: '#000' }} 
                          itemStyle={{ color: '#000' }} 
                        />
                        <Legend />
                        <Line 
                          type="monotone" 
                          dataKey="Profit" 
                          stroke="#ffa500" 
                          name="Annual Profit" 
                          strokeWidth={2} 
                          dot={{ r: 4 }} 
                          activeDot={{ r: 6 }} 
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                
              </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default Colo1;
