
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';

const generatePDF3 = async (formData, coloTableData, coloOpExData, colo2Costs) => {
  const doc = new jsPDF('p', 'mm', 'a4');
  
  // Title
  doc.setFontSize(16);
  doc.text('Data Center Configuration Report', 15, 15);
  doc.setFontSize(10);
  doc.text(`Generated on: ${new Date().toLocaleDateString()}`, 15, 25);

  let yPos = 35; 


  const addSection = (title, content) => {
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.text(title, 15, yPos);
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(10);
    
    const lines = doc.splitTextToSize(content, 180);
    doc.text(lines, 15, yPos + 7);
    
    yPos += 7 + (lines.length * 5);
  };

  // **Colocation Provider & Facility Details**
  let content = `Building Ownership: ${formData.colo2buildingOwnership}\n`;
  content += formData.colo2buildingOwnership === 'leased' 
    ? `Lease Rate: $${formData.colo2leaseRate}\n`
    : `Core & Shell: ${formData.colo2coreShellCost} ft²\n`;
  content += `Power Needed: ${formData.colo2powerCapacity / 1000} MW\n`;
  content += `Cooling Approach: ${formData.colo2coolingApproach}\n`;
  content += `Air Distribution Type: ${formData.colo2airDistributionType}\n`;
  content += `UPS Style: ${formData.colo2upsStyle}\n`;
  content += `Power Distribution Type: ${formData.colo2powerDistributionType}`;
  addSection('COLOCATION II PROVIDER & FACILITY DETAILS', content);
  
  //Colo Offered Options
  content = `Rack Availability: ${formData.colo2rackAvailability}\n`;
  content += `Colo Rate: $${formData.colo2coloRate} per kW/month\n`;
  content += `Rack Space Rental Rate: $${formData.colo2rackSpaceRentalRates} per ft²/month\n`;
  content += `Optional Add-On: ${formData.optionalAddOn}\n`;
  content += `UPS & Generator Maintenance:{formData.colo2upsContract ? 'Yes' : 'No'}\n`;
  addSection('COLOCATION OFFERED OPTIONS', content);

  //redundancy levels
  content = `Power Redundancy:\n`;
  content += `IT Distribution: ${formData.colo2itDistributionRedundancy}\n`;
  content += `UPS: ${formData.colo2upsRedundancy}\n`;
  content += `Generator: ${formData.colo2generatorRedundancy}\n`;
  content += `Cooling Redundancy:\n`;
  content += `CRAH/CRAC: ${formData.colo2crahCracRedundancy}\n`;
  content += `Pumps: ${formData.colo2pumpsRedundancy}\n`;
  content += `Chiller: ${formData.colo2chillerRedundancy}\n`;
  content += `Heat Rejection: ${formData.colo2heatRejectionRedundancy}`;
  addSection('REDUNDANCY LEVELS', content);

  //additional considerations
  content = `Environmental Goals: ${formData.colo2environmentalGoals ? 'Yes' : 'No'}\n`;
  if (formData.colo2environmentalGoals) {
    content += `PUE Target: ${formData.colo2pueTarget}\n`;
  }
  addSection('ADDITIONAL CONSIDERATIONS', content);

  // **Financial Summary Table**
  function convertToInteger(value) {
    if (typeof value === 'number') {
      return value; 
    }
    if (typeof value === 'string') {
      if (value.includes(',')) {
        return parseInt(value.replace(/,/g, ''), 10); 
      } else {
        return parseInt(value, 10); 
      }
    }
    return NaN;
  }

  doc.addPage();
  doc.setFontSize(14);
  doc.text('Results', 15, 15);
  doc.text('Financial Yearly Summary', 15, 35);

  doc.autoTable({
    startY: 45,
    head: [['Year', 'Revenue ($)', 'CapEx ($)', 'OpEx ($)', 'Profit ($)']],
    body: [
      ...coloTableData.map(row => [
        row.year,
        row.Revenue.toLocaleString(),
        row.CapEx.toLocaleString(),
        row.OpEx.toLocaleString(),
        (convertToInteger(row.Revenue) - (convertToInteger(row.CapEx) + convertToInteger(row.OpEx))).toLocaleString()
      ]),
      // **Total row**
      [
        'Total',
        coloTableData.reduce((sum, row) => sum + convertToInteger(row.Revenue), 0).toLocaleString(),
        coloTableData.reduce((sum, row) => sum + convertToInteger(row.CapEx), 0).toLocaleString(),
        coloTableData.reduce((sum, row) => sum + convertToInteger(row.OpEx), 0).toLocaleString(),
        coloTableData.reduce((acc, row) => acc + (convertToInteger(row.Revenue) - (convertToInteger(row.CapEx) + convertToInteger(row.OpEx))), 0).toLocaleString()
      ]
    ],
    theme: 'grid',
    styles: { fontSize: 10, cellPadding: 2 },
    headStyles: { fillColor: [41, 128, 185], textColor: 255, fontSize: 10 },
  });



  // Add a page for charts
  doc.addPage();
  yPos = 25;

  const getChartSize = () => {
    if (window.innerWidth >= 3840) {
      return { imgWidth: 150, imgHeight: 50 }; // 4XL Screens (3840px)
    } else if (window.innerWidth >= 2560) {
      return { imgWidth: 150, imgHeight: 50 }; // 2XL Screens (2560px)
    }
    return { imgWidth: 160, imgHeight: 60 }; // Default (for <2560px)
  };

  // Capture and add charts to the PDF
  const titlearray = ['OpEx Vs Revenue Analysis',  'CapEx Breakdown', 'Profit Analysis'];
  const charts = document.querySelectorAll('.recharts-wrapper'); 

  for (let i = 0; i < charts.length; i++) {
    if (yPos > 160) {
      doc.addPage();
      yPos = 20;
    }

    try {
      if (i === 1) {
        yPos += 10;
        doc.text(`Total CapEx: $${colo2Costs.colo2TotalCapEx.toLocaleString()}`, 15, yPos);
        yPos += 10;
      }
      doc.text(titlearray[i], 15, yPos);
      yPos += 10;
      const chartCanvas = await html2canvas(charts[i], {
        scale: 5,
        useCORS: true,
  
        logging: false,
      });
      const chartImage = chartCanvas.toDataURL('image/png');
      const { imgWidth, imgHeight } = getChartSize();

      doc.addImage(chartImage, 'PNG', 15, yPos, imgWidth, imgHeight);
      yPos += 95;
    } catch (error) {
      console.error('Error capturing chart:', error);
    }
  }

  // Save the PDF
  doc.save(`datacenter-config-${new Date().toISOString().split('T')[0]}.pdf`);
};

export default generatePDF3;
