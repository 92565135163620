
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';

const generatePDF2 = async (formData, coloTableData, coloOpExData, coloCosts) => {
  const doc = new jsPDF('p', 'mm', 'a4');
  
  // Title
  doc.setFontSize(16);
  doc.text('Data Center Configuration Report', 15, 15);
  doc.setFontSize(10);
  doc.text(`Generated on: ${new Date().toLocaleDateString()}`, 15, 25);

  let yPos = 35; 


  const addSection = (title, content) => {
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.text(title, 15, yPos);
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(10);
    
    const lines = doc.splitTextToSize(content, 180);
    doc.text(lines, 15, yPos + 7);
    
    yPos += 7 + (lines.length * 5);
  };

  // **Colocation Provider & Facility Details**
  let content = `Colo Provider: ${formData.coloProvider}\n`;
  content += `Colo Rate: $${formData.coloRate} per kW\n`;
  content += `Power Needed: ${formData.coloPowerCapacity / 1000} MW\n`;
  content += `Cooling Approach: ${formData.coloCoolingApproach}\n`;
  content += `Air Distribution Type: ${formData.coloAirDistributionType}\n`;
  content += `UPS Style: ${formData.coloUpsStyle}\n`;
  content += `Power Distribution Type: ${formData.coloPowerDistributionType}`;
  addSection('COLOCATION PROVIDER & FACILITY DETAILS', content);

  // **Colocation Hardware & Software**
  content = `Server Type: ${formData.coloServerType}\n`;
  if (formData.coloServerType === 'gpu') {
    content += `GPU Brand: ${formData.coloGpuBrand}\n`;
    content += `GPU Model: ${formData.coloGpuModel}\n`;
    content += `GPU Servers per Rack: ${formData.coloGpuServersPerRack}\n`;
  }
  if (formData.coloServerType === 'cpu') {
    content += `CPU Rack Count: ${formData.coloCPUrackCount}\n`;
  }
  if (formData.coloServerType === 'gpu') {
    content += `GPU Rack Count: ${formData.coloGPUrackCount}\n`;
  }
  if (formData.coloServerType === 'both') {
    content += `CPU Rack Count: ${formData.coloCPUrackCount}\n`;
    content += `GPU Rack Count: ${formData.coloGPUrackCount}\n`;
  }
  content += `GPU Servers per Rack: ${formData.coloGpuServersPerRack}\n`;
  content += `Power Density: ${formData.coloPowerDensity} kW/rack\n`;
  content += `Network Type: ${formData.coloNetworkType}\n`;
  content += `Operating System: ${formData.coloOperatingSystem}\n`;
  content += `HPC Software: ${formData.coloHpcSoftware}`;
  addSection('COLOCATION HARDWARE & SOFTWARE', content);

  // **Staffing & Maintenance**
  content = `IT Staff Count: ${formData.coloItStaffCount}\n`;
  content += `Labor Rate: $${formData.coloLaborRate}/hr\n`;
  content += `Facilities Staff: ${formData.coloFacilitiesStaff ? 'Yes' : 'No'}\n`;
  if (formData.coloFacilitiesStaff) {
    content += `Facilities Staff Count: ${formData.coloFacilitiesStaffCount}\n`;
  }
  content += `Outsource Hardware: ${formData.coloOutsourceHardware ? 'Yes' : 'No'}\n`;
  content += `Approx. Annual Contract Cost: $${formData.coloMaintenanceCost}\n`;
  addSection('STAFFING & MAINTENANCE', content);

  // additional Considerations
  content = `Environmental Goals: ${formData.coloEnvironmentalGoals ? 'Yes' : 'No'}\n`;
  if (formData.coloEnvironmentalGoals) {
    content += `PUE Target: ${formData.coloPueTarget}\n`;
  }
  addSection('ADDITIONAL CONSIDERATIONS', content);

  // **Financial Summary Table**
  function convertToInteger(value) {
    if (typeof value === 'number') {
      return value; 
    }
    if (typeof value === 'string') {
      if (value.includes(',')) {
        return parseInt(value.replace(/,/g, ''), 10); 
      } else {
        return parseInt(value, 10); 
      }
    }
    return NaN;
  }

  doc.addPage();
  doc.setFontSize(14);
  doc.text('Results', 15, 15);
  doc.text('Financial Yearly Summary', 15, 35);

  // **Financial Yearly Summary Table**
  doc.text('Yearly Financial Summary', 15, 25);
  doc.autoTable({
    startY: 45,
    head: [['Year', 'Revenue ($)', 'CapEx ($)', 'OpEx ($)', 'Profit ($)']],
    body: [
      ...coloTableData.map(row => [
        row.year,
        row.Revenue.toLocaleString(),
        row.CapEx.toLocaleString(),
        row.OpEx.toLocaleString(),
        (convertToInteger(row.Revenue) - (convertToInteger(row.CapEx) + convertToInteger(row.OpEx))).toLocaleString()
      ]),
      // **Total row**
      [
        'Total',
        coloTableData.reduce((sum, row) => sum + convertToInteger(row.Revenue), 0).toLocaleString(),
        coloTableData.reduce((sum, row) => sum + convertToInteger(row.CapEx), 0).toLocaleString(),
        coloTableData.reduce((sum, row) => sum + convertToInteger(row.OpEx), 0).toLocaleString(),
        coloTableData.reduce((acc, row) => acc + (convertToInteger(row.Revenue) - (convertToInteger(row.CapEx) + convertToInteger(row.OpEx))), 0).toLocaleString()
      ]
    ],
    theme: 'grid', 
    styles: { fontSize: 10, cellPadding: 2 },
    headStyles: { fillColor: [41, 128, 185], textColor: 255, fontSize: 10 },
  });

  // **OpEx Breakdown Table**

  doc.text(`Monthly OpEx: $${coloCosts?.coloMonthlyOpEx?.toLocaleString() || 0}`, 15, 120);
  doc.text('OpEx Breakdown', 15, 130);

  doc.autoTable({
    startY: 140,
    head: [['Name', 'Value ($)', 'Percentage (%)']],
    body: coloOpExData.map(item => [
      item.name,
      `$${(item.value || 0).toLocaleString()}`,
      `${((item.value / (coloCosts?.coloMonthlyOpEx || 1)) * 100).toFixed(2)}%`
    ]),
    theme: 'grid',
    styles: { fontSize: 10, cellPadding: 2 },
    headStyles: { fillColor: [41, 128, 185], textColor: 255, fontSize: 10 },
  });

  // Add a page for charts
  doc.addPage();
  yPos = 25;

  const getChartSize = () => {
    if (window.innerWidth >= 3840) {
      return { imgWidth: 150, imgHeight: 50 }; // 4XL Screens (3840px)
    } else if (window.innerWidth >= 2560) {
      return { imgWidth: 150, imgHeight: 50 }; // 2XL Screens (2560px)
    }
    return { imgWidth: 160, imgHeight: 60 }; // Default (for <2560px)
  };

  // Capture and add charts to the PDF
  const titlearray = ['OpEx Vs Revenue Analysis',  'CapEx Breakdown', 'Profit Analysis'];
  const charts = document.querySelectorAll('.recharts-wrapper'); 

  for (let i = 0; i < charts.length; i++) {
    if (yPos > 160) {
      doc.addPage();
      yPos = 20;
    }

    try {
      if (i === 1) {
        yPos += 10;
        doc.text(`Total CapEx: $${coloCosts.coloTotalCapEx.toLocaleString()}`, 15, yPos);
        yPos += 10;
      }
      doc.text(titlearray[i], 15, yPos);
      yPos += 10;
      const chartCanvas = await html2canvas(charts[i], {
        scale: 5,
        useCORS: true,
  
        logging: false,
      });
      const chartImage = chartCanvas.toDataURL('image/png');
      const { imgWidth, imgHeight } = getChartSize();

      doc.addImage(chartImage, 'PNG', 15, yPos, imgWidth, imgHeight);
      yPos += 95;
    } catch (error) {
      console.error('Error capturing chart:', error);
    }
  }

  // Save the PDF
  doc.save(`datacenter-config-${new Date().toISOString().split('T')[0]}.pdf`);
};

export default generatePDF2;
