
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';

const generatePDF = async (formData, tableData, opExData, costs) => {
  const doc = new jsPDF('p', 'mm', 'a4');
  
  // Title
  doc.setFontSize(16);
  doc.text('Data Center Configuration Report', 15, 15);
  doc.setFontSize(10);
  doc.text(`Generated on: ${new Date().toLocaleDateString()}`, 15, 25);

  let yPos = 35; 


  const addSection = (title, content) => {
    doc.setFontSize(12);
    doc.setFont('helvetica', 'bold');
    doc.text(title, 15, yPos);
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(10);
    
    const lines = doc.splitTextToSize(content, 180);
    doc.text(lines, 15, yPos + 7);
    
    yPos += 7 + (lines.length * 5);
  };

  // Section 1: Site & Facilities
  let content = `Building Ownership: ${formData.buildingOwnership}\n`;
  content += formData.buildingOwnership === 'leased' 
    ? `Lease Rate: $${formData.leaseRate}\n`
    : `Core & Shell: ${formData.coreShellCost} ft²\n`;
  content += `Power Needed: ${formData.powerCapacity / 1000} MW\n`;
  content += `Cooling Approach: ${formData.coolingApproach}\n`;
  content += `Air Distribution Type: ${formData.airDistributionType}\n`;
  content += `UPS Style: ${formData.upsStyle}\n`;
  content += `Power Distribution Type: ${formData.powerDistributionType}`;
  addSection('SITE & FACILITIES', content);

  // Section 2: Hardware and Software
  content = `Server Type: ${formData.serverType}\n`;
  if (formData.serverType === 'gpu') {
    content += `GPU Brand: ${formData.gpuBrand}\n`;
    content += `GPU Model: ${formData.gpuModel}\n`;
    content += `GPU Servers per Rack: ${formData.gpuServersPerRack}\n`;
  }
  if (formData.serverType === 'cpu') {
    content += `CPU Rack Count: ${formData.CPUrackCount}\n`;
  }
  if (formData.serverType === 'gpu') {
    content += `GPU Rack Count: ${formData.GPUrackCount}\n`;
  }
  if (formData.serverType === 'both') {
    content += `CPU Rack Count: ${formData.CPUrackCount}\n`;
    content += `GPU Rack Count: ${formData.GPUrackCount}\n`;
  }
  content += `GPU Servers per Rack: ${formData.gpuServersPerRack}\n`;
  content += `Power Density: ${formData.powerDensity} kW/rack\n`;
  content += `Network Type: ${formData.networkType}\n`;
  content += `Operating System: ${formData.operatingSystem}\n`;
  content += `HPC Software: ${formData.hpcSoftware}`;
  addSection('HARDWARE AND SOFTWARE', content);

  // Section 3: Staffing & Maintenance
  content = `IT Staff Count: ${formData.itStaffCount}\n`;
  content += `Labor Rate: $${formData.laborRate}\n`;
  content += `Facilities Staff: ${formData.facilitiesStaff ? 'Yes' : 'No'}\n`;
  if (formData.facilitiesStaff) {
    content += `Facilities Staff Count: ${formData.facilitiesStaffCount}\n`;
  }
  content += `Outsource Hardware: ${formData.outsourceHardware ? 'Yes' : 'No'}\n`;
  content += `Approx. Annual Contract Cost: $${formData.maintenanceCost}\n`;
  content += `UPS & Generator Maintenance: {formData.upsContract ? 'Yes' : 'No'}\n`;
  addSection('STAFFING & MAINTENANCE', content);

  // Section 4: Redundancy Levels
  content = `Power Redundancy:\n`;
  content += `IT Distribution: ${formData.itDistributionRedundancy}\n`;
  content += `UPS: ${formData.upsRedundancy}\n`;
  content += `Generator: ${formData.generatorRedundancy}\n`;
  content += `Cooling Redundancy\n`;
  content += `CRAH/CRAC: ${formData.crahCracRedundancy}\n`;
  content += `Pumps: ${formData.pumpsRedundancy}\n`;
  content += `Chiller: ${formData.chillerRedundancy}\n`;
  content += `Heat Rejection: ${formData.heatRejectionRedundancy}`;
  addSection('REDUNDANCY LEVELS', content);

  // Section 5: Environmental Goals
  content = `Environmental Goals: ${formData.environmentalGoals ? 'Yes' : 'No'}\n`;
  if (formData.environmentalGoals) {
    content += `PUE Target: ${formData.pueTarget}\n`;
  }
  addSection('ENVIRONMENTAL GOALS', content);

  // **Financial Summary Table**
  function convertToInteger(value) {
    if (typeof value === 'number') {
      return value; 
    }
    if (typeof value === 'string') {
      if (value.includes(',')) {
        return parseInt(value.replace(/,/g, ''), 10); 
      } else {
        return parseInt(value, 10); 
      }
    }
    return NaN;
  }

  doc.addPage();
  doc.setFontSize(14);
  doc.text('Results', 15, 15);
  doc.text('Financial Yearly Summary', 15, 35);

  doc.autoTable({
    startY: 45,
    head: [['Year', 'Revenue ($)', 'CapEx ($)', 'OpEx ($)', 'Profit ($)']],
    body: tableData.map(row => [
      row.year,
      row.Revenue.toLocaleString(),
      row.CapEx.toLocaleString(),
      row.OpEx.toLocaleString(),
      (convertToInteger(row.Revenue) - (convertToInteger(row.CapEx) + convertToInteger(row.OpEx))).toLocaleString()
    ]),
    theme: 'grid', 
    styles: { fontSize: 10, cellPadding: 2 },
    headStyles: { fillColor: [41, 128, 185], textColor: 255, fontSize: 10 },
  });

  // **OpEx Breakdown Table**
  doc.text(`Monthly OpEx: $${costs.monthlyOpEx.toLocaleString()}`, 15, 120);
  doc.text('OpEx Breakdown', 15, 130);

  doc.autoTable({
    startY: 140,
    head: [['Name', 'Value ($)', 'Percentage (%)']],
    body: opExData.map(item => [
      item.name,
      `$${item.value.toLocaleString()}`,
      `${((item.value / costs.monthlyOpEx) * 100).toFixed(2)}%`
    ]),
    theme: 'grid',
    styles: { fontSize: 10, cellPadding: 2 },
    headStyles: { fillColor: [41, 128, 185], textColor: 255, fontSize: 10 },
  });

  // Add a page for charts
  doc.addPage();
  yPos = 25;

  const getChartSize = () => {
    if (window.innerWidth >= 3840) {
      return { imgWidth: 150, imgHeight: 50 }; // 4XL Screens (3840px)
    } else if (window.innerWidth >= 2560) {
      return { imgWidth: 150, imgHeight: 50 }; // 2XL Screens (2560px)
    }
    return { imgWidth: 160, imgHeight: 60 }; // Default (for <2560px)
  };

  // Capture and add charts to the PDF
  const titlearray = ['OpEx Vs Revenue Analysis', 'Profit Analysis', 'CapEx Breakdown', 'Infrastructure Systems Distribution'];
  const charts = document.querySelectorAll('.recharts-wrapper'); 

  for (let i = 0; i < charts.length; i++) {
    if (yPos > 160) {
      doc.addPage();
      yPos = 20;
    }

    try {
      if (i === 2) {
        yPos += 10;
        doc.text(`Total CapEx: $${costs.totalCapEx.toLocaleString()}`, 15, yPos);
        yPos += 10;
      }
      doc.text(titlearray[i], 15, yPos);
      yPos += 10;
      const chartCanvas = await html2canvas(charts[i], {
        scale: 5,
        useCORS: true,
  
        logging: false,
      });
      const chartImage = chartCanvas.toDataURL('image/png');
      const { imgWidth, imgHeight } = getChartSize();
      

      doc.addImage(chartImage, 'PNG', 15, yPos, imgWidth, imgHeight);
      yPos += 95;
    } catch (error) {
      console.error('Error capturing chart:', error);
    }
  }

  // Save the PDF
  doc.save(`datacenter-config-${new Date().toISOString().split('T')[0]}.pdf`);
};

export default generatePDF;
